import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Observable, shareReplay} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {InformationService} from "../../../../../../services/information/information.service";
import {Language} from "../../../../../../utilities/types/language.type";
import {ArticleFragment, CategoryItemFragment} from "../../../../../../graphql/content-auto-graphql.service";
import {CategoriesTreeItem} from "./types/categories-tree-item.type";

@Component({
  selector: 'app-information-page-menu',
  templateUrl: './information-page-menu.component.html',
  styleUrl: './information-page-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationPageMenuComponent {
  @Input({required: true}) lang!: Language;
  @Input({required: true}) hasArticle!: boolean;
  categoriesTree$!: Observable<CategoriesTreeItem[]>;
  categoriesTreeLoading$!: Observable<boolean>;
  countryInformationType: 'cis' | 'other' = 'cis';

  drawerCategoriesVisible = false;
  categoriesMenuPaddingLeft = 15;

  constructor(
    private route: ActivatedRoute,
    private informationService: InformationService,
    private cdr: ChangeDetectorRef
  ) {
    const params$ = this.route.params.pipe(
      map(params => {
        this.countryInformationType = params['countryInformationType'];
        return params['countryInformationType'] === 'cis' ? 'ru' : 'another';
      })
    );

    this.categoriesTree$ = params$.pipe(
      switchMap((countryInformationType) => this.fetchCategories(countryInformationType)),
      shareReplay(1)
    );
  }


  private fetchCategories(countryRegion: string): Observable<CategoriesTreeItem[]> {
    const categoriesQueryRef = this.informationService.fetchCategories(countryRegion);
    this.categoriesTreeLoading$ = categoriesQueryRef.loading;
    return categoriesQueryRef.data.pipe(
      map((result) => {
        if (result?.categories?.items) {
          const sortedCategories = [...result.categories.items].sort((a, b) => b.weight - a.weight);
          return this.getCategoriesTree(sortedCategories);
        }
        return [];
      })
    );
  }

  private getCategoriesTree(categories: Array<CategoryItemFragment> | undefined): CategoriesTreeItem[] {
    const rootsCategories = [];
    const rootsCategoriesChild: Record<number, CategoryItemFragment[]> = {};
    const categoriesTree: CategoriesTreeItem[] = [];

    if (categories && categories?.length > 0) {
      for (const category of categories) {
        if (category.parentId === null) {
          rootsCategories.push(category);
          continue;
        }

        if (!rootsCategoriesChild.hasOwnProperty(category.parentId)) {
          rootsCategoriesChild[category.parentId] = [];
        }

        rootsCategoriesChild[category.parentId].push(category);
      }

      for (const rootCategory of rootsCategories) {
        categoriesTree.push(this.getCategoriesTreeItem(rootCategory, rootsCategoriesChild, 1));
      }

      return categoriesTree;
    }

    return [];
  }

  private getCategoriesTreeItem(
    parentCategory: CategoryItemFragment,
    rootsCategoriesChild: Record<number, CategoryItemFragment[]>,
    level: number,
  ): CategoriesTreeItem {
    const articles = parentCategory.articles ? [...parentCategory.articles] : [];

    articles.sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

    if (!rootsCategoriesChild.hasOwnProperty(parentCategory.id)) {
      return {menuLevel: level, category: {...parentCategory, articles}, childCategories: []};
    }

    const childCategories: CategoriesTreeItem[] = rootsCategoriesChild[parentCategory.id].map(
      (child) => this.getCategoriesTreeItem(child, rootsCategoriesChild, level + 1)
    );

    return {menuLevel: level, category: {...parentCategory, articles}, childCategories};
  }

  onClickCategoriesVisible(): void {
    this.drawerCategoriesVisible = true;
    this.cdr.detectChanges();
  }

  onCloseCategoriesDrawer(): void {
    this.drawerCategoriesVisible = false;
  }
}
