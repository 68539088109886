import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ILot} from '../../../../../../services/lot-adapter/interfaces/lot.interface';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LotContactsModalComponent} from '../lot-contacts-modal/lot-contacts-modal.component';
import {CurrentUserServiceDataQuery} from '../../../../../../graphql/akebono-main-graphql.service';
import {TranslateService} from '@ngx-translate/core';
import {LotContactFormModalComponent} from '../lot-contact-form-modal/lot-contact-form-modal.component';
import {PriceDetail} from './types/lot-info.types';
import {Location} from '../../../../../../services/lot-adapter/types/location.type';
import {LotType} from '../../../../../../services/lot-adapter/enums/lot-type.enum';
import {
  LotUpcomingSailingsModalComponent
} from './components/lot-upcoming-sailings-modal/lot-upcoming-sailings-modal.component';
import {departmentIdByLotType} from "../../../../../../services/lot-adapter/constants/department-id-by-lot-type";
import {Observable} from "rxjs";
import {ExchangeRateService} from "../../../../../../services/exchange-rate/exchange-rate.service";

@Component({
  selector: 'app-lot-info',
  templateUrl: './lot-info.component.html',
  styleUrl: './lot-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotInfoComponent implements OnInit {
  @Input({required: true}) lot!: ILot;
  @Input({required: true}) currentUser: CurrentUserServiceDataQuery['currentUser'];
  @Input({required: true}) activeLotType!: LotType;
  priceDetails: PriceDetail[] = [];
  jpyToRub$: Observable<number> | undefined;
  usdToRub$: Observable<number> | undefined;
  LotType = LotType;

  constructor(
    private nzModalService: NzModalService,
    private translate: TranslateService,
    private exchangeRateService: ExchangeRateService,
  ) {
  }

  ngOnInit(): void {
    this.initPriceDetails();
    this.fetchExchangeRates();
  }

  private initPriceDetails(): void {
    this.priceDetails = [
      {
        priceValue: this.lot.priceInJapan,
        label: 'LOT.FIELDS.JAPAN_PRICE',
        isMain: this.isJpLocation,
      },
      {
        priceValue: this.lot.priceOnDelivery,
        label: 'LOT.FIELDS.TRANSIT_PRICE',
        isMain: this.isTransportLocation,
      },
      {
        priceValue: this.lot.priceInStock,
        label: 'LOT.FIELDS.MONGOLIA_PRICE',
        isMain: this.isMnLocation,
      },
      {priceValue: this.lot.price, label: 'LOT.FIELDS.PRICE', isMain: true},
    ];
  }

  private fetchExchangeRates(): void {
    if (this.activeLotType !== LotType.GreenCorner) {
      return;
    }

    this.jpyToRub$ = this.exchangeRateService.rubToJpyExchangeRate$;
    this.usdToRub$ = this.exchangeRateService.usdToRubExchangeRate$;
  }

  openApplicationModal(): void {
    this.nzModalService.create({
      nzTitle: this.translate.instant('LOT_APPLICATION_MODAL.FILL_OUT_APPLICATION'),
      nzContent: LotContactFormModalComponent,
      nzWidth: '60%',
      nzData: {
        ...this.contactFormModalData,
        activeLotType: this.activeLotType,
      },
      nzFooter: null,
    });
  }

  openUpcomingSailingsModal(): void {
    this.nzModalService.create({
      nzContent: LotUpcomingSailingsModalComponent,
      nzWidth: '80%',
      nzTitle: undefined,
      nzFooter: null,
    });
  }

  openContactsModal(): void {
    this.nzModalService.create({
      nzTitle: this.translate.instant('LOT_APPLICATION_MODAL.SELLER_CONTACTS'),
      nzContent: LotContactsModalComponent,
      nzWidth: '60%',
      nzFooter: null,
    });
  }

  private get contactFormModalData(): Record<string, unknown> {
    return {
      lotId: this.lot.akebonoLotId || this.lot?.id?.toString(),
      lotType: this.lot?.akebonoLotType,
      lotSource: this.lot?.akebonoLotSource,
      objectGUID: this.lot?.guid,
      departmentId: departmentIdByLotType[this.activeLotType as keyof typeof departmentIdByLotType],
      wholesalePrice: this.lot.price?.value,
      wholesalePriceCurrency: this.lot.price?.unit,
      currentUser: this.currentUser,
    };
  }

  private get isMnLocation(): boolean {
    return [Location.MONGOLIA, Location.ULAANBAATAR, Location.ZAMIIN_UUD].includes(
      this.lot?.location as Location,
    );
  }

  private get isJpLocation(): boolean {
    return this.lot?.location === Location.JAPAN;
  }

  private get isTransportLocation(): boolean {
    return this.lot?.location?.toLowerCase() === Location.TRANSPORTING;
  }
}
