<section class="main-info">
  <div class="main-info-wrapper">
    @if (!lot.isSold) {
      @for (priceDetail of priceDetails; track priceDetail.label) {
        @if (priceDetail.priceValue) {
          <app-lot-info-price
            [priceDetail]="priceDetail"
            [activeLotType]="activeLotType"
            [jpyToRubRate]="jpyToRub$ | push"
            [usdToRubRate]="usdToRub$ | push"
          ></app-lot-info-price>
        }
      }
    }

    <nz-list class="list">
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar
            [nzSrc]="'/assets/icons/auction-grade.svg'"
          ></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>
            {{ 'LOT.FIELDS.AUCTION_GRADE' | translate }}
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          {{ lot.rating || '--' }}
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar [nzSrc]="'/assets/icons/year.svg'"></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.YEAR' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          {{ lot.year || '--' }}
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar
            [nzSrc]="'/assets/icons/color-car.svg'"
          ></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.COLOR' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          {{ (lot.color | uppercase) || '--' }}
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar
            [nzSrc]="'/assets/icons/mileage.svg'"
          ></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.MILEAGE' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          @if (lot.mileage?.value && lot.mileage?.unit) {
            {{ lot.mileage?.value }} {{ lot.mileage?.unit }}
          } @else {
            {{ '--' }}
          }
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar
            [nzSrc]="'/assets/icons/car-class.svg'"
          ></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.CLASS' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          {{ lot.model || '--' }}
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar
            [nzSrc]="'/assets/icons/transmission.svg'"
          ></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>
            {{ 'LOT.FIELDS.TRANSMISSION' | translate }}
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          {{ lot.transmission || '--' }}
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar
            [nzSrc]="'/assets/icons/location.svg'"
          ></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.LOCATION' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          {{ (lot.location | titlecase) || '--' }}
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar [nzSrc]="'/assets/icons/engine.svg'"></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.ENGINE' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          @if (lot.engineVolume?.value && lot.engineVolume?.unit) {
            {{ lot.engineVolume?.value }} {{ lot.engineVolume?.unit }}
          } @else {
            {{ '--' }}
          }
        </span>
      </nz-list-item>
      <nz-list-item>
        <nz-list-item-meta>
          <nz-list-item-meta-avatar [nzSrc]="'/assets/icons/vin.svg'"></nz-list-item-meta-avatar>
          <nz-list-item-meta-title>{{ 'LOT.FIELDS.VIN' | translate }}</nz-list-item-meta-title>
        </nz-list-item-meta>
        <span class="value">
          @if (lot.type === LotType.Usa) {
            {{ lot.vin || '--' }}
          } @else {
            {{ lot.vin || '--' | vinMask }}
          }
        </span>
      </nz-list-item>
    </nz-list>
  </div>

  <div class="main-info-add-info">{{ lot.accident }}</div>

  @if (!lot.isSold) {
    @if (activeLotType === 'green') {
      <nz-row [nzGutter]="[10, 10]">
        <nz-col [nzSpan]="19">
          <button app-button size="large" appType="primary" (click)="openApplicationModal()">
            {{ 'LOT.ACTIONS.SUBMIT_APPLICATION' | translate }}
          </button>
        </nz-col>
        <nz-col [nzSpan]="5">
          <button app-button size="large" appType="primary" (click)="openContactsModal()">
            <span nz-icon nzType="icons:phone" style="padding: 4px"></span>
          </button>
        </nz-col>
      </nz-row>

      <button
        app-button
        appType="primary-ghost"
        size="large"
        [block]="true"
        (click)="openUpcomingSailingsModal()"
      >
        {{ 'LOT.UPCOMING_SAILINGS.BUTTON' | translate }}
      </button>
    } @else {
      <button
        app-button
        size="large"
        appType="primary"
        (click)="openApplicationModal()"
        style="width: 100%"
      >
        {{ 'LOT.ACTIONS.SUBMIT_APPLICATION' | translate }}
      </button>
    }
  }
</section>
