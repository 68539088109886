import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Value } from 'src/app/services/lot-adapter/types/value.type';
import {LotType} from "../../../../services/lot-adapter/enums/lot-type.enum";

@Component({
  selector: 'app-lot-card-info',
  templateUrl: './lot-card-info.component.html',
  styleUrl: './lot-card-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCardInfoComponent {
  @Input({required: true}) lotType?: LotType;
  @Input({required: true}) accident?: string;
  @Input({required: true}) mileage?: Value;
  @Input({required: true}) location?: string;
  @Input({required: true}) color?: string;
  @Input({required: true}) vinNumber?: string;
  LotType = LotType;
}
