<div>
  <div class="info">
    @if (mileage) {
      <app-info-icon icon="mileage"> {{ mileage.value | number }} {{ mileage.unit }}</app-info-icon>
    }
    @if (color) {
      <app-info-icon icon="color-car">{{ color | titlecase }}</app-info-icon>
    }
  </div>
  <div class="info">
    @if (location) {
      <app-info-icon icon="address">{{ location | titlecase }}</app-info-icon>
    }
    @if (vinNumber) {
      @if (lotType === LotType.Usa) {
        <app-info-icon icon="vin">
          {{ vinNumber }}
        </app-info-icon>
      } @else {
        <app-info-icon icon="vin">
          {{ vinNumber | vinMask }}
        </app-info-icon>
      }
    }
  </div>
</div>

<div>
  @if (accident) {
    <p>{{ accident }}</p>
  }
</div>
