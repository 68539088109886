import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILotMinimal } from 'src/app/services/lot-adapter/interfaces/lot-minimal.interface';
import { LotType } from "../../services/lot-adapter/enums/lot-type.enum";

@Component({
  selector: 'app-lot-card',
  templateUrl: './lot-card.component.html',
  styleUrl: './lot-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCardComponent {
  @Input({ required: true }) lot!: ILotMinimal;
  LotType = LotType;
}
