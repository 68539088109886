<h1>{{ maker }}</h1>
<p>
  {{ mark }} {{ model }} {{ grade! | parseHtml }} {{ engineVolume?.value }}
  {{ engineVolume?.unit | lowercase }} {{ bodyType }} {{ drive }}
</p>

<div class="footer">
  <p>{{ year }}</p>
  @if (!isSold) {
  <h2>
    {{
      price?.value === 0
        ? ('LOT.ON_REQUEST' | translate)
        : (price?.value | currency : price?.unit : 'symbol-narrow' : '1.0-2')
    }}
  </h2>
  }
</div>
